<template>
  <b-row align-v="center" class="bg-secondary p-2 mb-2">
    <b-col cols="auto">
      <div class="p-2">
        <i :class="`fa fa-${icon}`" />
      </div>
    </b-col>
    <b-col>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <strong class="h6">{{ title.toUpperCase() }}</strong>
          <br />
          <span class="h5" v-if="subtitle" style="font-weight: 400">{{ subtitle }}</span>
        </div>

        <div>
          <slot name="additional"/>
        </div>
      </div>
      <hr class="m-0" />
      <slot />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SupplierDetailsTitle',
  props: { title: { type: String }, subtitle: { type: String }, icon: { type: String, default: 'list' } },
}
</script>
