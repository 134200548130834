<template>
  <b-row>
    <b-col class="border mx-2">
      <supplier-details-title title="Products" :subtitle="`${variations.length}`">
        <b-row>
          <b-col cols="auto">{{ variationMeta.active }} Active</b-col>
          <b-col cols="auto">{{ variationMeta.bookable }} Bookable</b-col>
          <b-col cols="auto">{{ variationMeta.inactive }} Inactive</b-col>
        </b-row>
      </supplier-details-title>

      <b-table :fields="variationFields" :items="variations" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(product_name)="{ item }">
          <router-link :to="{ name: 'products.edit', params: { id: item.product_id } }">
            {{ item.variation_name }}
          </router-link>
        </template>
        <template v-slot:cell(status)="{ item }">
          <b-badge :variant="statusVariant(item.status)">
            {{ statusText(item.status).toUpperCase() }}
          </b-badge>
        </template>
      </b-table>

      <validation-observer ref="supplierForm">
        <validation-provider class="text-center" v-slot="context" rules="required">
            <b-form-group>
              <b-form-radio-group
                  :disabled="!supplier.can_update_status"
                  v-model="supplierStatus"
                  button-variant="primary"
                  :options="statusOptions"
                  v-bind="context.ariaInput"
                  buttons
                  @change="submitFormData(supplier)"
              />
              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
      </validation-observer>

    </b-col>
    <b-col class="border mx-2">
      <supplier-details-title title="Paid to suppliers" :subtitle="`${invoice.retailSumExcludingVat} kr`">
        Our commission: {{ invoice.currentInvoiceCommission?.toFixed(2) }} ({{ invoice.percentage }}%)
      </supplier-details-title>

      <line-chart v-if="chartData" :data="chartData" :options="chartOptions" class="w-100" style="height: 190px" />
    </b-col>
    <b-col class="border mx-2">
      <supplier-details-title title="Invoices" :subtitle="`${invoice.invoices.length}`">
        <b-row>
          <b-col cols="auto">{{ invoiceMeta.paid }} Paid</b-col>
          <b-col cols="auto">{{ invoiceMeta.not_paid }} Unpaid</b-col>
        </b-row>

        <template #additional>
          <b-button variant="danger"  :disabled="isLoading" @click="downloadInvoices">
            Download
            <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
          </b-button>
        </template>
      </supplier-details-title>

      <b-table :fields="invoicesFields" :items="invoice.invoices" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(pdf)="{ item }">
          <a href="#" @click.prevent="downloadInvoice(item.id)">
            <i class="fa fa-download" />
            <span>&nbsp; Download</span>
          </a>
        </template>

        <template v-slot:cell(status)="{ item }">
          <b-badge :variant="`${item.paid_at ? 'primary' : 'secondary'}`">
            {{ item.paid_at ? 'PAID' : 'UNPAID' }}
          </b-badge>
        </template>
      </b-table>
    </b-col>
    <b-col class="border mx-2">
      <supplier-details-title title="Booking Dates" subtitle="No Data">
        No Data
      </supplier-details-title>

      <h3 style="font-weight: 300">
        Invoices
        <b-button class="float-right" size="sm" variant="danger" v-b-modal="`generate-invoice`" :disabled="generateInvoiceLoading">
          Generate
          <b-spinner v-show="generateInvoiceLoading" class="ml-2" small label="Loading" />
        </b-button>
      </h3>
      <b-modal title="Confirm Invoice Generation" :id="`generate-invoice`" @ok="generateInvoice">
        Are you sure that you want to generate an invoice?
      </b-modal>
      <ul>
        <li>
          To be invoiced: <strong>{{ invoice.onNextInvoice.length }}</strong>
        </li>
        <li>
          Can be added to invoice: <strong>{{ invoice.canBeInvoiced.length }}</strong>
        </li>
        <li>
          Next Invoice Sum: <strong>{{ invoice.nextInvoiceSum }}</strong>
        </li>
        <li>
          Next Invoice Commision: <strong>{{ invoice.nextInvoiceCommission?.toFixed(2) }}</strong>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { invoices, products as productsApi, suppliers as supplierApi } from '@/api'
import SupplierDetailsTitle from './SupplierDetailsTitle'
import LineChart from '../components/vue-chartjs/LineChart'
import { formatDate } from '@/pages/page-helpers'
import {downloadFile} from "@/helpers/files";

export default {
  name: 'SupplierDetails',
  components: { LineChart, SupplierDetailsTitle },
  props: { supplier: { type: Object } },
  data() {
    return {
      variations: [],
      invoice: {},
      supplierStatus: '',
      isLoading: false,
      generateInvoiceLoading: false,
    }
  },
  computed: {
    variationFields() {
      return [
        { key: 'product_name', label: 'Name' },
        { key: 'place', formatter: place => place.name },
        { key: 'status' },
      ]
    },
    variationMeta() {
      return this.variations.reduce(
        (accumulator, current) => {
          const key = this.statusText(current.status)
          accumulator[key] = accumulator[key] + 1
          return accumulator
        },
        { active: 0, bookable: 0, inactive: 0 }
      )
    },
    invoicesFields() {
      return [
        { key: 'id', label: '#' },
        {
          key: 'created_at',
          label: 'Date',
          formatter: (value, key, item) => {
            return formatDate(value)
          },
        },
        { key: 'pdf', label: 'Download' },
        { key: 'status' },
      ]
    },
    invoiceMeta() {
      return this.invoice.invoices.reduce(
        (accumulator, current) => {
          const key = this.invoiceText(current.paid_at)
          accumulator[key] = accumulator[key] + 1
          return accumulator
        },
        { paid: 0, not_paid: 0 }
      )
    },
    chartData() {
      const labels = []
      const data = []

      if (this.invoice.chart) {
        for (const i in this.invoice.chart) {
          labels.push(i)
          data.push(this.invoice.chart[i])
        }
      }

      return {
        labels,
        datasets: [
          {
            color: '#640DF3',
            backgroundColor: 'transparent',
            borderColor: '#640DF3',
            borderWidth: 2,
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 2,
            pointRadius: 4,
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#640DF3',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 2,
            data,
          },
        ],
      }
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.000001,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 1000,
              },
            },
          ],
        },
      }
    },
    statusOptions() {
      return [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Bookable', value: 'bookable' },
      ]
    }

  },
  mounted() {
    this.fresh(false)
  },
  methods: {
    fresh(invoiceNotification = true) {
      Promise.all([
        productsApi.variations.all(null, { filter: { supplier_id: this.supplier.id, pagination: 0 } }),
        supplierApi.invoices(this.supplier.id),
      ])
        .then(([variations, invoice]) => {
          this.variations = variations.data()
          this.invoice = invoice.data

          this.$emit('loaded', false)
        })
        .then(() => {
          invoiceNotification && this.$bvToast.toast(`Invoice was generated.`, {
            title: 'Invoice was generated',
            variant: 'primary',
          })
        })
        .catch(() => {
          invoiceNotification && this.$bvToast.toast(`Invoice not generated.`, {
            title: 'Invoice not generated',
            variant: 'danger',
          })
        })
    },
    async downloadInvoice(id) {
      const res = await invoices.download(id)
      window.open(res.url, '_blank').focus()
    },
    async downloadInvoices() {
      this.isLoading = true;

      const blob = await invoices.downloadZipOrXlsx({supplier_id: this.supplier.id, format: 'zip'})

      downloadFile(blob, 'invoices.zip');

      this.isLoading = false;
    },
    statusVariant(status) {
      return { 0: 'default', 1: 'primary', 2: 'secondary' }[status]
    },
    statusText(status) {
      return { 0: 'inactive', 1: 'active', 2: 'bookable' }[status]
    },
    invoiceText(paid_at) {
      return paid_at ? 'paid' : 'not_paid'
    },
    async generateInvoice() {
      this.generateInvoiceLoading = true;
      await invoices.generate(this.supplier.id)
      this.generateInvoiceLoading = false;
      this.fresh()
    },

    async submitFormData(supplier) {
      try {
        await this.$store.dispatch('suppliers/update', { status: this.supplierStatus, id: supplier.id });

        this.$bvToast.toast(`Product variations statuses updated to ${this.supplierStatus}`, {
          title: 'Supplier status updated',
          variant: 'primary',
        });

        this.fresh(false)
      } catch (error) {
        this.$refs.supplierForm.setErrors(error.errors)
      }

      this.supplierStatus = ''
    },
  },
}
</script>
