<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Suppliers <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'suppliers.new' }" :disabled="$cannot('supplier.manage')">
          New Supplier
        </b-button>
      </b-col>
    </b-row>

    <!-- search -->
    <display-results :index-name="suppliersIndex" v-slot:default="{ items }">
      <b-table
        :fields="fields"
        :items="mapItems(items)"
        show-empty
        bordered
        hover
        stacked="sm"
        @row-clicked="row => showDetails(row)"
      >
        <template v-slot:cell(options)="row">
          <div class="d-flex align-items-center">
            <b-dropdown variant="default" size="sm" text="Options" offset="25" right>
              <b-dropdown-item
                :to="{ name: 'suppliers.edit', params: { id: row.item.id } }"
                :disabled="$cannot('supplier.manage')"
              >
                Edit
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'suppliers.bookings', params: { id: row.item.id } }"
                :disabled="$cannot('supplier.booking')"
              >
                Bookings
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'suppliers.users', params: { id: row.item.id } }"
                :disabled="$cannot('supplier.booking')"
              >
                Users
              </b-dropdown-item>

              <b-dropdown-item
                size="sm"
                :to="{ name: 'supplier.gallery', params: { id: row.item.id } }"
                :disabled="$cannot('supplier.manage')"
              >
                Images &amp; Content
              </b-dropdown-item>
            </b-dropdown>

            <div class="ml-2" v-if="row.item.first_supplier_user_id">
              <b-button variant="primary" @click="impersonate(row.item)" size="sm">Log in as supplier</b-button>

              <b-modal :id="`not-impersonate-${row.item.first_supplier_user_id}`" title="Impersonation Forbidden" ok-only>
                This user is an administrator, as such impersonation is strictly forbidden.
              </b-modal>
            </div>
          </div>
        </template>

        <template v-slot:row-details="{ item }">
          <b-overlay :show="loading === item.id">
            <supplier-details :supplier="item" @loaded="loading = 0" />
          </b-overlay>
        </template>
      </b-table>
    </display-results>
  </div>
</template>

<script>
import DisplayResults from '../components/DisplayResults.vue'
import SupplierDetails from './SupplierDetails.vue'
import {users} from "@/api";

export default {
  name: 'SuppliersPage',
  inject: ['$cannot'],
  components: { DisplayResults, SupplierDetails },
  data() {
    return { suppliersIndex: process.env.VUE_APP_ALGOLIA_SUPPLIERS_INDEX, loading: 0, current: 0 }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'booking_name', sortable: false, label: 'Name' },
        { key: 'invoice_legal_name', sortable: false, label: 'Legal name' },
        { key: 'booking_email', sortable: false, label: 'Email' },
        { key: 'invoice_org_nr', sortable: false, label: 'Org no.' },
        { key: 'invoice_bank_number', sortable: false, label: 'Bank no.' },
        { key: 'country_code', sortable: false, label: 'Country Code' },
        { key: 'options', label: '' },
      ]
    },
  },
  methods: {
    mapItems(items) {
      return items.map(item => ({ ...item, _showDetails: this.current === item.id }))
    },
    showDetails(supplier) {
      this.loading = supplier.id
      this.current = supplier.id === this.current ? 0 : supplier.id
    },
    async impersonate(user) {
      try {
        await users.impersonate(user.first_supplier_user_id)

        window.open(process.env.VUE_APP_SUPPLIER_DASHBOARD_URL, '_blank').focus()
      } catch (error) {
        if (error.code === 'request.forbidden') {
          this.$bvModal.show(`not-impersonate-${user.first_supplier_user_id}`)
        }
      }
    },
  },
}
</script>
